import STYLE_MANAGER from "../../style-manager";

import createTheme from "@mui/material/styles/createTheme";
import { createRoot } from "react-dom/client";
import { AuthenticatedShopifyExtensionClientProvider } from "../../client/authenticated-client-context";
import { RedoRoot } from "../../root";
import { ContactForm } from "./contact-form";

void import("./styles.css");

function initialize() {
  const target = document.getElementById("redo-contact-form-target");
  if (!target) {
    return;
  }

  RedoShadowHost.register();

  const shadowHost = document.createElement(
    RedoShadowHost.TAG,
  ) as RedoShadowHost;

  shadowHost.style.display = "flex";
  shadowHost.style.justifyContent = "center";
  shadowHost.style.width = "80%";

  target.appendChild(shadowHost);
  const root = createRoot(shadowHost.root);

  // Create a second shadow host for MUI components
  const muiShadowHost = document.createElement(
    RedoShadowHost.TAG,
  ) as RedoShadowHost;
  document.body.appendChild(muiShadowHost);
  const muiRoot = document.createElement("div");
  muiShadowHost.root.appendChild(muiRoot);

  const muiTheme = createTheme({
    components: {
      MuiPopper: {
        defaultProps: {
          container: muiRoot,
        },
      },
      MuiModal: {
        defaultProps: {
          container: muiRoot,
        },
      },
    },
  });
  root.render(
    <RedoRoot
      extensionShopifyClient={undefined}
      muiTheme={muiTheme}
      storeUrl={window.Shopify?.shop ?? window.Shopify?.Checkout?.apiHost}
    >
      <AuthenticatedShopifyExtensionClientProvider
        baseUrl={process.env.REDO_SHOPIFY_SERVER_URL!}
        storeUrl={Shopify?.shop}
      >
        <ContactForm />
      </AuthenticatedShopifyExtensionClientProvider>
    </RedoRoot>,
  );
}

class RedoShadowHost extends HTMLElement {
  #deregisterStyles?: () => void;
  #root!: ShadowRoot;
  get root() {
    return this.#root;
  }

  static #registered = false;
  static readonly TAG = "redo-shadow-host";

  connectedCallback() {
    this.#root = this.attachShadow({ mode: "open" });
    this.#deregisterStyles = STYLE_MANAGER.register(this.#root);
  }

  disconnectedCallback() {
    this.#deregisterStyles?.();
  }

  static register() {
    if (!this.#registered) {
      customElements.define(this.TAG, RedoShadowHost);
      this.#registered = true;
    }
  }
}

initialize();
