import { Context, useContext } from "react";

export class MissingContextError<T> extends Error {
  constructor(readonly context: Context<T>) {
    super(`No value provided for ${context.displayName || "context"}`);
  }
}

/**
 * useContext, but throws error if undefined
 * @param context Context
 */
export function useRequiredContext<T>(context: Context<T | undefined>): T {
  const value = useContext(context);
  if (value === undefined) {
    throw new MissingContextError(context);
  }
  return value;
}
