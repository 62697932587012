import { SnackbarOrigin } from "@mui/material/Snackbar";
import { Resource } from "@redotech/util/resource";
import { SnackbarKey, closeSnackbar, enqueueSnackbar } from "notistack";
import { IconButton, IconButtonTheme } from "./button";
import XIcon from "./icon-old/x.svg";

const defaultOrigin: SnackbarOrigin = {
  horizontal: "center",
  vertical: "bottom",
};
const defaultDuration = Temporal.Duration.from({ seconds: 5 });

function close(snackbarId: SnackbarKey) {
  return (
    <IconButton
      onClick={() => closeSnackbar(snackbarId)}
      theme={IconButtonTheme.GRAY}
    >
      <XIcon />
    </IconButton>
  );
}

export function alertOnFailure(
  message?: string,
  hideErrorMessage = false,
): Resource<void> {
  return async (fn) => {
    try {
      return await fn();
    } catch (error) {
      const alertMessage = `${message ?? ""}${!hideErrorMessage && error instanceof Error && !!error.message ? `${message ? ": " : ""}${error.message}` : ""}`;
      console.error(error);
      enqueueSnackbar(alertMessage, {
        anchorOrigin: defaultOrigin,
        autoHideDuration: defaultDuration.total("milliseconds"),
        variant: "error",
        action: close,
      });
      throw error;
    }
  };
}

// for some reason, using SnackbarOrigin directly
// causes a cursed build error
interface ISnackbarOrigin {
  horizontal: "left" | "center" | "right";
  vertical: "top" | "bottom";
}

export function toast(
  message: string,
  options: {
    variant: "error" | "info" | "success" | "warning";
    origin?: ISnackbarOrigin;
    duration?: Temporal.Duration;
    persist?: boolean;
  },
): void {
  enqueueSnackbar(message, {
    anchorOrigin: options.origin ?? defaultOrigin,
    autoHideDuration: options.persist
      ? null
      : options.duration?.total("milliseconds") ??
        defaultDuration.total("milliseconds"),
    variant: options.variant,
    action: close,
  });
}
