// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../k8-opt-exec-ST-13d3ddad9198/bin/redo/shopify-extension/webpack.bin.runfiles/npm_css-loader_6.7.3-dc3fc578/files/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._h-0 {\n  display: flex;\n  gap: 10px;\n}\n\n._h-0._h-1 {\n  align-items: center;\n}\n\n._h-0._h-2 {\n  flex-direction: column;\n  color: var(--redo-colors-text-text-primary-900);\n}\n\n._h-3 {\n  /* align-self: flex-start; */\n  font-weight: bold;\n  font-size: var(--redo-body-small-text-size);\n  color: var(--redo-colors-text-text-secondary-700);\n}\n\n._h-0._h-4 ._h-3 {\n  font-size: var(--redo-body-medium-text-size);\n  font-weight: normal;\n}\n\n._h-0._h-5 ._h-3 {\n  font-size: var(--redo-body-small-text-size);\n  font-weight: 500;\n}\n\n._h-0._h-6 ._h-3 {\n  font-size: var(--redo-body-small-text-size);\n  font-weight: normal;\n}\n\n._h-7 {\n  color: var(--redo-colors-text-text-error-primary-600);\n}\n", "",{"version":3,"sources":["webpack://./../web/src/labeled-input.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,+CAA+C;AACjD;;AAEA;EACE,4BAA4B;EAC5B,iBAAiB;EACjB,2CAA2C;EAC3C,iDAAiD;AACnD;;AAEA;EACE,4CAA4C;EAC5C,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;EAC3C,gBAAgB;AAClB;;AAEA;EACE,2CAA2C;EAC3C,mBAAmB;AACrB;;AAEA;EACE,qDAAqD;AACvD","sourcesContent":[".container {\n  display: flex;\n  gap: 10px;\n}\n\n.container.horizontal {\n  align-items: center;\n}\n\n.container.vertical {\n  flex-direction: column;\n  color: var(--redo-colors-text-text-primary-900);\n}\n\n.label {\n  /* align-self: flex-start; */\n  font-weight: bold;\n  font-size: var(--redo-body-small-text-size);\n  color: var(--redo-colors-text-text-secondary-700);\n}\n\n.container.light .label {\n  font-size: var(--redo-body-medium-text-size);\n  font-weight: normal;\n}\n\n.container.thin-bold .label {\n  font-size: var(--redo-body-small-text-size);\n  font-weight: 500;\n}\n\n.container.thin .label {\n  font-size: var(--redo-body-small-text-size);\n  font-weight: normal;\n}\n\n.error {\n  color: var(--redo-colors-text-text-error-primary-600);\n}\n"],"sourceRoot":""}]);
// Exports
export var container = "_h-0";
export var horizontal = "_h-1";
export var vertical = "_h-2";
export var label = "_h-3";
export var light = "_h-4";
export var thinBold = "_h-5";
export var thin = "_h-6";
export var error = "_h-7";
export default ___CSS_LOADER_EXPORT___;
