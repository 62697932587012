import { RedoClient } from "@redotech/redo-api-client";
import { ReactNode, createContext } from "react";
import { AuthenticatedShopifyExtensionClient } from "./authenticated-shopify-extension-client";

export const AuthenticatedShopifyExtensionClientContext = createContext<
  AuthenticatedShopifyExtensionClient | undefined
>(undefined);

/**
 * baseUrl: The base URL for the Redo shopify-server (not using the Redo CDN).
 */
export function AuthenticatedShopifyExtensionClientProvider({
  baseUrl,
  children,
  storeUrl,
}: {
  baseUrl: string;
  children: ReactNode | ReactNode[];
  storeUrl?: string;
}) {
  const client = new RedoClient(baseUrl);
  const shopifyExtensionClient = new AuthenticatedShopifyExtensionClient(
    client,
    storeUrl,
  );
  return (
    <AuthenticatedShopifyExtensionClientContext.Provider
      value={shopifyExtensionClient}
    >
      {children}
    </AuthenticatedShopifyExtensionClientContext.Provider>
  );
}
